import React from "react";
import {Link} from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";

import {Backdrop, CircularProgress} from "@mui/material";

import "../style/MainContainer.css";

interface Props {
  children: React.ReactNode;
  loading?: boolean;
}

const MainContainer = ({children, loading} : Props) => {

  const signOutUser = async () => {
    await signOut(getAuth());
  };

  if(loading) {
    return (
      <>
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  return (
    <>
      <div className="navbar">
        <div className="navbar_element--left">
          <Link to="/" className="navbar__button">
            Easy APC
          </Link>
        </div>
        <div className="navbar_element--right">
          <Link to="/settings" className="navbar__button">
            Einstellungen
          </Link>
          <a className="navbar__button" onClick={async () => await signOutUser()}>
            Abmelden
          </a>
        </div>
      </div>
      <div className="container--page">
        {children}
      </div>
    </>
  );
};

export default MainContainer;
