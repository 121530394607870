import {User} from "firebase/auth";

import {useDocs} from "../hooks/useDatabase";

import MainContainer from "../components/MainContainer";

import "../style/Settings.css";
import React, {useEffect, useState} from "react";
import Status, {StatusInfo} from "../components/Status";
import ApiUserRows, {ApiUserRow, ApiUsers, WrappedUsers} from "../components/ApiUserRows";
import {setDeepValue} from "../utils/dbUtils";
import {doc, setDoc} from "firebase/firestore";
import {db} from "../config/firebase";

interface Settings {
  apiUsers: WrappedUsers;
  triggerTag: {
    value: string;
  };
  notificationEmail: {
    value: string;
  }
}

interface Props {
  pageLoading: boolean;
  firebaseUser?: User | null;
}

const Settings = ({pageLoading, firebaseUser}: Props) => {

  const defaultSettings: Settings = useDocs("settings", true) as Settings;

  const [settings, setSettings] = useState<Settings>();

  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<StatusInfo>();

  const loading = !settings;

  useEffect(() => {
    setSettings(defaultSettings);
  }, [defaultSettings]);

  const submitHandling = async (ev: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    ev.preventDefault();
    setActionLoading(true);
    try {

      let element: ApiUserRow;
      for (element of settings?.apiUsers.users || []) {
        if(element.reference === "" || element.apiKey === "") {
          throw new Error("API-Benutzer-Tabelle ist unvollständig. Bitte füllen Sie alle Felder aus.");
        }
      }

      let key: string;
      for (key in settings) {
        await setDoc(doc(db, "settings", key), {...(settings as any)[key]});
      }
      setStatus({message: "Einstellungen erfolgreich gespeichert.", type: "success"});
    }
    catch(error: any) {
      setStatus({message: error.message, type: "error"})
    }
    finally {
      setActionLoading(false);
    }
  };

  return (
    <MainContainer loading={loading}>
      <Status loading={actionLoading} status={status} />
      <div className="container--vertical">
        <div className="container--split">
          <ApiUserRows
              apiUsers={[
                  ...settings?.apiUsers?.users || [],
                {
                  reference: "",
                  apiKey: "",
                  active: true
                }
              ]}
              onChange={(users: ApiUsers) => setSettings(setDeepValue(settings || {}, "apiUsers", users, "users"))}
          />
          <div className="container--vertical">
            <h3>Andere Einstellungen</h3>
            <div className="container--horizontal">
              <label htmlFor="triggerTagInput">Auslöser-Tag:</label>
              <input
                type="text"
                id="triggerTagInput"
                value={settings?.triggerTag?.value || ""}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setSettings(setDeepValue(settings || {}, "triggerTag", ev.target.value, "value"))}
              />
            </div>
            <div className="container--horizontal">
              <label htmlFor="notificationEmailInput">Benachrichtigungen an:</label>
              <input
                type="email"
                id="notificationEmailInput"
                value={settings?.notificationEmail?.value || ""}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setSettings(setDeepValue(settings || {}, "notificationEmail", ev.target.value, "value"))}
              />
            </div>
          </div>
        </div>
        <div className="text-align-center">
          <input type="submit" value="Speichern" onClick={submitHandling} />
        </div>
      </div>
    </MainContainer>
  );
};

export default Settings;
