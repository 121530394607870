import React from "react";

import "../style/PriceRows.css";
import {updateObjectArray} from "../utils/dbUtils";

export interface PriceElement {
  name: string;
  id: number | "";
  price: number | "";
}

export type Prices = Array<PriceElement>;

export interface SavedPricesEntry {
  prices: Prices
}

interface Props {
  products?: Array<any>;
  prices: Prices;
  onChange: (prices: Prices, deletion?: boolean) => void;
  onSaveClick: () => void;
}

const PriceRows = ({products, prices, onChange, onSaveClick} : Props) => {
  const matchingProduct = (id: number | "") => products?.find(product => product.id === id);

  const updateId = (index: number, value: number | "") => {
    if(matchingProduct(value)) {
      onChange(updateObjectArray(prices, index, {id: value, name: matchingProduct(value).name}));
    }
    else {
      onChange(updateObjectArray(prices, index, {id: value, name: ""}));
    }
  };

  const deletePriceFromList = (index: number) => {
    onChange(
      prices.filter((element, i) => {
        return index !== i;
      }),
      true
    );
  };

  return (
    <div className="container--vertical">
      <h2>Preis-Änderungen</h2>

      <table className="prices-list">
        <thead>
          <tr>
            <th></th>
            <th className="prices-list__name">Name</th>
            <th>ID</th>
            <th>Preis</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            prices.map((element, index) => {
              return (
                <tr className="prices-list__row" key={index}>
                  <td>{ index + 1 }.</td>
                  <td className="prices-list__name">{ element.name }</td>
                  <td>
                    <input
                      type="number"
                      value={prices[index].id}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => updateId(index, ev.target.valueAsNumber || "")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step=".1"
                      value={prices[index].price}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(updateObjectArray(prices, index, {price: ev.target.valueAsNumber || ""}))}
                    />
                  </td>
                  <td><button onClick={() => deletePriceFromList(index)}>Löschen</button></td>
                </tr>
              );
            })
          }
        </tbody>
      </table>

      <div className="container--space-between">
        <button onClick={() => onChange(prices.concat({name: "", id: "", price: ""}))}>Neue Zeile</button>
        <button onClick={() => onChange([], true)}>Alle Löschen</button>
      </div>

      <div>
        <button onClick={onSaveClick}>Speichern</button>
      </div>
    </div>
  );
};

export default PriceRows;
