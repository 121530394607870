import React, {useCallback, useEffect, useMemo, useState} from "react";

import "../style/Logs.css";
import {tsToDt} from "../utils/timeUtils";
import {Checkbox, CircularProgress, FormControl, FormControlLabel, MenuItem, Select} from "@mui/material";
import {useOnSnapshotLogs} from "../hooks/useDatabase";
import LogEntryRow from "./LogEntryRow";

export interface ProcessLogEntry {
  name: string,
  createdAt: number
}

export interface ProcessLogEntries {
  [key: string]: ProcessLogEntry;
}

export interface LogEntry {
  message: string;
  type: 0 | 1;
  createdAt: number;
  subLogs: Array<any>;
}

export interface LogEntries {
  [key: string]: LogEntry;
}

interface Props {
  processLogs: ProcessLogEntries
}

const Logs = ({processLogs}: Props) => {
  const [selectedLog, setSelectedLog] = useState<string>(Object.keys(processLogs)[0] || "");

  const path = selectedLog ? "processLogs/" + selectedLog + "/logs" : undefined;
  const processLogEntries: LogEntries = useOnSnapshotLogs(path, true) as LogEntries || {}

  const [filter, setFilter] = useState<Array<number>>([0, 1, 2]);

  const toggleFilter = (value: number) => {
    if(filter.includes(value)) {
      setFilter(filter => filter.filter((ele) => ele !== value))
    } else {
      setFilter(filter => [...filter, value] );
    }
  }

  const renderedLogs: Array<LogEntry> = useMemo(() => {
    const logData = Object.entries(processLogEntries)
    .map(([key, value]) => {
      return value;
    });
    return logData.filter(log => filter.includes(log.type));
  }, [filter, processLogEntries]);

  //return useMemo(() => {
    return (
      <div className="container--vertical">
        <h2>Logs</h2>
        <div className="container--horizontal">
          <FormControl fullWidth size="small">
            <Select
              labelId="logSelect"
              displayEmpty
              value={selectedLog}
              onChange={(event) => setSelectedLog(event.target.value)}
            >
              {
                Object.entries(processLogs)
                .map(([key, value]) => {
                  return <MenuItem key={key} value={key}>{tsToDt(value.createdAt)}: {value.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox checked={filter.includes(1)} onChange={() => toggleFilter(1)} name="info"/>
            }
            label="Info"
          />
          <FormControlLabel
            control={
              <Checkbox checked={filter.includes(0)} onChange={() => toggleFilter(0)} name="error"/>
            }
            label="Error"
          />
        </div>
        <div className="container--horizontal">

        </div>

        <div className="container--logs">
          {
            (renderedLogs.length === 0 && !!selectedLog) && (
              <CircularProgress/>
            )
          }
          {
            renderedLogs
            .map((log, index) => {
              return (
                <LogEntryRow log={log} key={index}/>
              );
            })
          }
        </div>
      </div>
    );
 // }, [processLogEntries, processLogs, filter]);
};

export default Logs;
