import React, {useEffect, useMemo, useState} from "react";

import {ExpandMore, InfoOutlined, WarningAmber} from "@mui/icons-material";

import "../style/Logs.css";
import { LogEntry } from "./Logs";
import {tsToDt} from "../utils/timeUtils";
interface Props {
  log: LogEntry;
}

const LogEntryRow = ({log} : Props) => {

  const [openedLog, setOpenedLog] = useState<boolean>(false);

  const getColor = (type: number): string => {
    if(type === 2) {
      return "#ed6c02";
    }
    else if(type === 0) {
      return "#d32f2f";
    }
    return "initial";
  };

  const getIcon = (type: number): JSX.Element => {
    if(type === 2) {
      return <WarningAmber fontSize="small" />;
    }
    else if(type === 0) {
      return <WarningAmber fontSize="small" />;
    }
    return <InfoOutlined fontSize="small" />;
  }

  const getText = (type: number): string => {
    if(type === 2) {
      return "(warnung)";
    }
    else if(type === 0) {
      return "(fehlgeschlagen)";
    }
    return "(erfolgreich)";
  };


  return useMemo(() => {
    return (
      <div className="log" style={{color: getColor(log.type)}}>
        <div className="log__summary">
          <div>
            { getIcon(log.type) }
            { tsToDt(log.createdAt) }: { log.message } { log.subLogs.length > 0 && getText(log.type) }
          </div>
          <div
            style={{visibility: log.subLogs.length > 0 ? "inherit" : "hidden"}}
            onClick={() => setOpenedLog(!openedLog)}>
            <ExpandMore />
          </div>
        </div>
        <div className="log__unfold" style={{display: openedLog ? "inherit" : "none"}}>
          {
            log.subLogs.length > 0 && log.subLogs.map((subLog, index) => {
              return (
                <div className="log__summary" style={{color: getColor(subLog.type)}} key={index}>
                  <div>
                    { getIcon(subLog.type) }
                    { subLog.message }
                  </div>
                  <div style={{visibility: "hidden"}}>
                    <ExpandMore />
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }, [log, openedLog]);
};

export default LogEntryRow;
