import { useEffect, useState } from "react";
import {getAvailableProducts} from "../config/cloudFunctions";
import {ApiUsers} from "../components/ApiUserRows";

export function useAvailableProducts(apiUsers: ApiUsers) {
    const [data, setData] = useState<any>(undefined);

    useEffect(() => {
        let isSubscribed: boolean = true;

        if(apiUsers) {
            getAvailableProducts({apiUsers}).then((res) => {
                if (isSubscribed) {
                    setData(res.data);
                }
            });
        }

        return () => {
            isSubscribed = false;
        }
    }, [apiUsers]);

    return data;
}