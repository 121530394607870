import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase.config";

import { getAuth, EmailAuthProvider, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator} from "firebase/functions";
import {getFirestore, connectFirestoreEmulator,} from "firebase/firestore";

export const Firebase = initializeApp(firebaseConfig);

export const Providers = { emailAuth: new EmailAuthProvider() };

export const auth = getAuth();

export const cloudFunctions = getFunctions();
export const db = getFirestore();

if(process.env.NODE_ENV == "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(cloudFunctions, "localhost", 5001);
  connectFirestoreEmulator(db, "localhost", 8080);
}