import React from "react";

import {Alert, AlertColor, LinearProgress} from "@mui/material";

import "../style/Status.css";

export interface StatusInfo {
  message: string;
  type: AlertColor;
}

interface Props {
  loading: boolean;
  status?: StatusInfo;
}

const Status = ({loading, status} : Props) => {
  if(loading) {
    return (
      <div className="container--status">
        <LinearProgress />
      </div>
    );
  }

  return (
    <div className="container--status">
      {status?.message && <Alert severity={status?.type}>{status?.message}</Alert>}
    </div>
  );
};

export default Status;
