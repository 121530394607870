import React from "react";

import "../style/ConfirmationModal.css";

export interface ModalInfo {
  show: boolean;
  headline?: string;
  text?: string;
  callback?: () => void;
}

interface Props {
  modalInfo: ModalInfo;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal = ({modalInfo, onClose, onConfirm} : Props) => {
  return (
    <>
      <div className="modal" style={{display: modalInfo.show ? "" : "none"}}>
        <div className="container--modal">
          <div className="modal__navbar">
            <h3>{modalInfo.headline}</h3>
            <button onClick={onClose}>X</button>
          </div>
          <div className="container--vertical">
            <div>{modalInfo.text}</div>
            <button onClick={onConfirm}>Löschen</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
