import React, {useState} from "react";

import "../style/ExecutionOptions.css";

interface Props {
  onClick: (executeNow: boolean, time: number, allowLowerPrices: boolean) => void;
}

const ExecutionOptions = ({onClick} : Props) => {

  const [executeNow, setExecuteNow] = useState<boolean>(false);
  const [executionTime, setExecutionTime] = useState<number>(NaN);
  const [allowLowerPrices, setAllowLowerPrices] = useState<boolean>(false);

  const submitHandling = (ev: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    ev.preventDefault();
    if(executeNow) {
      onClick(executeNow, Date.now(), allowLowerPrices);
    }
    else {
      onClick(executeNow, executionTime, allowLowerPrices);
    }
  };

  const setTime = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setExecutionTime(new Date(ev.target.value).getTime());
  };

  return (
    <div className="container--vertical">
      <h2>Ausführungszeitpunk</h2>

      <form className="container--vertical">
        <div className="container--horizontal">
          <div>
            <input type="checkbox" id="executeNowCheckbox" checked={executeNow}
                   onChange={() => setExecuteNow(!executeNow)}/>
            <label htmlFor="executeNowCheckbox">Jetzt ausführen</label>
          </div>
          <div>
            <input type="checkbox" id="allowLowerPricesCheckboxes" checked={allowLowerPrices}
                   onChange={() => setAllowLowerPrices(!allowLowerPrices)}/>
            <label htmlFor="allowLowerPricesCheckboxes">Niedrigere Preise erlauben</label>
          </div>
        </div>

        <div className="container--horizontal" style={executeNow ? {opacity: 0.5} : { opacity: 1 }}>
          <label htmlFor="executionTimeInput">Zeitpunk der automatischen Preisänderung:<div className="small-text">Zeitzone Wien, Österreich (GMT+2)</div></label>
          <input
            type="datetime-local"
            id="executionTimeInput"
            min={new Date().toISOString().slice(0,16)}
            max={new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toISOString().slice(0,16)}
            disabled={executeNow}
            onChange={setTime}
          />
        </div>

        <div>
          <input type="submit" value={executeNow ? "Jetzt durchführen" : "Durchführung planen"} onClick={submitHandling} />
        </div>
      </form>

    </div>
  );
};

export default ExecutionOptions;
