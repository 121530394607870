import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./firebase";

export const newLogin = httpsCallable(cloudFunctions, "newLogin");
export const verifyLogin = httpsCallable(cloudFunctions, "verifyLogin");

export const getAvailableProducts = httpsCallable(cloudFunctions, "getAvailableProducts");

export const getUpcomingTasks = httpsCallable(cloudFunctions, "getUpcomingTasks");

export const deleteTask = httpsCallable(cloudFunctions, "deleteTask");

export const enqueuePricePush = httpsCallable(cloudFunctions, "enqueuePricePush");

export const testing = httpsCallable(cloudFunctions, "testing");

