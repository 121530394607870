import React from "react";

import "../style/Status.css";
import {tsToDt} from "../utils/timeUtils";

import "../style/UpcomingTasks.css";
import {CircularProgress} from "@mui/material";

interface Props {
  tasks: any;
  onRefresh: () => void;
  onDelete: (name: string) => void;
}

const UpcomingTasks = ({tasks, onRefresh, onDelete} : Props) => {

  if(!tasks) {
    return (
      <>
        <h2>Anstehende Aktionen</h2>
        <CircularProgress/>
      </>
    );
  }

  return (
    <div className="container--upcoming-tasks container--vertical">
      <div className="container--horizontal">
        <h2>Anstehende Aktionen</h2>
        <button onClick={onRefresh}>Aktualisieren</button>
      </div>
      <table className="upcoming-tasks__table">
        <thead>
        <tr>
          <th>Art</th>
          <th>Ausführungs-Zeitpunkt</th>
          <th>Erstellungs-Zeitpunkt</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          tasks.map((element: any, index: number) => {
            return (
              <tr className="" key={index}>
                <td>Preis-Änderung</td>
                <td>{ tsToDt(element.scheduleTime.seconds * 1000) }</td>
                <td>{ tsToDt(element.createTime.seconds * 1000) }</td>
                <td><button onClick={() => onDelete(element.name)}>Löschen</button></td>
              </tr>
            );
          })
        }
        </tbody>
      </table>
    </div>
  );
};

export default UpcomingTasks;
