import { User } from "firebase/auth";

import { ReactElement, useEffect } from "react";
import { useNavigate, useLocation, NavigateFunction, Location } from "react-router-dom";

import { useFirebaseUser } from "../../hooks/userHook";

interface Props {
  render: (loading: boolean, firebaseUser: User | null | undefined) => ReactElement<any>;
}

const AuthChecker = ({ render }: Props) => {

  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  const firebaseUser: User | null | undefined = useFirebaseUser();

  const navigateTo = (path: string) => {
    if(location.pathname !== path) {
      navigate(path);
    }
  };

  useEffect(() => {
    if(firebaseUser === null) {
      navigateTo("/login");
    }
  }, [firebaseUser]);

  return (
    <>
      {
        render(
          !firebaseUser,
          firebaseUser
        )
      }
    </>
  );
};

export default AuthChecker;
