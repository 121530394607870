import React, { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import "../style/Login.css";
import Status, {StatusInfo} from "../components/Status";
import {newLogin, verifyLogin} from "../config/cloudFunctions";

import {auth} from "../config/firebase";
import { signInWithCustomToken  } from "firebase/auth";

interface Props {
}

const Login = ({}: Props) => {

  const navigate: NavigateFunction = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string | false>(false);

  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<StatusInfo>();

  const signInFactor1 = async () => {
    setActionLoading(true);
    try {
      if(!email || !password) {
        throw new Error("Bitte füllen Sie alle Felder aus.");
      }
      const status: any = await newLogin({username: email, password});
      setVerificationCode("");
      setStatus({message: status.data.message, type: "info"});
    }
    catch (error: any) {
      setStatus({message: error.message, type: "error"});
    }
    finally {
      setActionLoading(false);
    }
  };

  const signInFactor2 = async () => {
    setActionLoading(true);
    try {
      if(!verificationCode) {
        throw new Error("Bitte füllen Sie alle Felder aus.");
      }
      const customToken: any = await verifyLogin({username: email, password, code: verificationCode});
      await signInWithCustomToken(auth, customToken.data);
      navigate("/");
    }
    catch (error: any) {
      setStatus({message: error.message, type: "error"})
      setActionLoading(false);
    }
  };

  return (
    <div className="container--login">
      <div className="container--vertical">
        <Status loading={actionLoading} status={status} />
        <h2>Anmelden</h2>
        <form className="container--vertical">
          <input
            type="email"
            placeholder="Email"
            autoComplete="username"
            disabled={verificationCode !== false}
            value={email}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setEmail(ev.target.value)}
          />
          <input
            type="password"
            placeholder="Passwort"
            autoComplete="current-password"
            disabled={verificationCode !== false}
            value={password}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setPassword(ev.target.value)}
          />
          {
            verificationCode !== false && (
              <input
                type="text"
                placeholder="Verifizierungs-Code"
                value={verificationCode}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setVerificationCode(ev.target.value)}
              />
            )
          }
          <input
            type="submit"
            value="Anmelden"
            onClick={async (ev: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              ev.preventDefault();
              if(verificationCode) {
                await signInFactor2();
              }
              else {
                await signInFactor1();
              }
            }}
          />
        </form>
      </div>

    </div>
  );
};

export default Login;
