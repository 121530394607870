import React from "react";

import {updateObjectArray} from "../utils/dbUtils";

import "../style/PriceRows.css";

export interface ApiUserRow {
  reference: string;
  apiKey: string;
  active: boolean;
}

export type ApiUsers = Array<ApiUserRow>;

export interface WrappedUsers {
    users: ApiUsers;
}

interface Props {
  apiUsers?: ApiUsers;
  onChange: (apiUsers: ApiUsers) => void;
}

const ApiUserRows = ({apiUsers, onChange} : Props) => {

  const updateUser = (index: number, overwrite: object) => {
      const updatedUsers = updateObjectArray(apiUsers, index, overwrite)
          .filter((element: ApiUserRow, index: number) => {
              return (element.reference + element.apiKey).length > 0;
          });
      onChange(updatedUsers);
  }

  return (
    <div className="container--vertical">
      <h2>Vendon API Benutzer</h2>

      <table className="prices-list">
        <thead>
          <tr>
            <th></th>
            <th>Referenzwert</th>
            <th>API-Key</th>
            <th>Aktiv</th>
          </tr>
        </thead>
        <tbody>
          {
            apiUsers?.map((element, index) => {
              return (
                <tr className="prices-list__row" key={index}>
                  <td>{ apiUsers?.length - 1 === index ? "-" : index + 1 + "." }</td>
                  <td>
                    <input
                      type="text"
                      value={element.reference}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => updateUser(index, {reference: ev.target.value})}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        value={element.apiKey}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => updateUser(index, {apiKey: ev.target.value})}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={element.active}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => updateUser(index, {active: ev.target.checked})}
                    />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default ApiUserRows;
