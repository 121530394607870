import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../config/firebase";

export function useFirebaseUser() {
    const [user, setUser] = useState<User | null>();

    useEffect(() => {
        let isSubscribed: boolean = true;
        const unsub = auth.onAuthStateChanged((user: User | null) => {
            if(isSubscribed) {
                setUser(user)
            }
        });
        return () => {
            isSubscribed = false;
            unsub();
        };
    }, []);

    return user;
}