import {User} from "firebase/auth";

import {useAvailableProducts} from "../hooks/apiHooks";
import {useDoc, useDocs, useOnSnapshotLogs} from "../hooks/useDatabase";

import MainContainer from "../components/MainContainer";
import Status, {StatusInfo} from "../components/Status";
import PriceRows, {PriceElement, Prices, SavedPricesEntry} from "../components/PriceRows";
import ExecutionOptions from "../components/ExecutionOptions";
import Logs, {LogEntries, ProcessLogEntries} from "../components/Logs";

import "../style/Home.css";
import {useEffect, useState} from "react";
import {doc, setDoc} from "firebase/firestore";
import {db} from "../config/firebase";
import {deleteTask, enqueuePricePush, getUpcomingTasks, testing} from "../config/cloudFunctions";
import Settings from "./Settings";
import UpcomingTasks from "../components/UpcomingTasks";
import ConfirmationModal, {ModalInfo} from "../components/ConfirmationModal";

interface Props {
  pageLoading: boolean;
  firebaseUser?: User | null;
}

const Home = ({pageLoading, firebaseUser}: Props) => {

  const [upcomingTasks, setUpcomingTasks] = useState<Array<any> | undefined>(undefined);

  const settings: Settings = useDocs("settings", true) as Settings;

  const processLogs: ProcessLogEntries = useOnSnapshotLogs("processLogs", true) as ProcessLogEntries;

  const availableProducts = useAvailableProducts(settings?.apiUsers?.users);

  const savedPrices: SavedPricesEntry = useDoc("prices", "current") as SavedPricesEntry;

  const [prices, setPrices] = useState<Prices>([{name: "", id: "", price: ""}]);

  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<StatusInfo>();

  const [modalInfo, setModalInfo] = useState<ModalInfo>({show: false});

  const loading: boolean = pageLoading || (!availableProducts && !!settings?.apiUsers) || !processLogs || savedPrices === undefined;

  useEffect(() => {
    if(savedPrices) {
      setPrices(savedPrices.prices);
    }
  }, [savedPrices]);

  useEffect(() => {
    refreshUpcoming();
  }, []);

  const refreshUpcoming = async () => {
    setUpcomingTasks(undefined);
    const upcomingReq: any = await getUpcomingTasks();
    setUpcomingTasks(upcomingReq.data);
  };

  const onConfirmation = async (text: string, callback: () => void) => {
    setModalInfo({
      show: true,
      headline: "Löschung bestätigen",
      text,
      callback
    });
  };

  const deleteTaskHandling = async (name: string) => {
    await onConfirmation("Sind Sie sich sicher, dass Sie diese anstehende Aktion löschen wollen?" , async () => {
      setActionLoading(true);
      try {
        await deleteTask({name});
        setUpcomingTasks(upcomingTasks?.filter((ele: any) => ele.name !== name));
        setStatus({message: "Geplante Aktion wurde gelöscht.", type: "success"})
      }
      catch(error: any) {
        setStatus({message: error.message, type: "error"})
      }
      finally {
        setActionLoading(false);
      }
    });
  };

  const push = async (executeNow: boolean, time: number, allowLowerPrices: boolean) => {
    setActionLoading(true);
    try {
      if(!time && !executeNow) {
        throw new Error("Ausführungszeitpunk ungültig. Bitte füllen Sie alle Felder aus.");
      }
      else if(time > Date.now() + 1000 * 60 * 60 * 24 * 30 && !executeNow) {
        throw new Error("Ausführungszeitpunk liegt zu weit in der Zukunft. Bitte wählen Sie einen Ausführungszeitpunkt in den nächsten 30 Tagen.");
      }
      else if(prices.length === 0) {
        throw new Error("Preis-Änderungen-Tabelle ist leer. Bitte erstellen Sie mindestens einen Eintrag.");
      }

      let price: PriceElement;
      for (price of prices) {
        if(price.id === "" || price.price === "") {
          throw new Error("Preis-Änderungen-Tabelle ist unvollständig. Bitte füllen Sie alle Felder aus.");
        }
      }
      await savePriceList(prices, true, true);
      await enqueuePricePush({
        time,
        executeNow,
        prices,
        allowLowerPrices,
        apiUsers: settings.apiUsers.users.filter((user) => user.active),
        triggerTag: settings.triggerTag.value,
        notificationEmail: settings.notificationEmail.value
      });
      if(!executeNow) {
        await refreshUpcoming();
      }
      setStatus({message: "Automaten-Anweisungen wurden abgeschickt. Siehe Logs für detailliertere Informationen.", type: "success"})
    }
    catch(error: any) {
      setStatus({message: error.message, type: "error"})
    }
    finally {
      setActionLoading(false);
    }
  };

  const savePriceList = async (newPrices=prices, silent: boolean=false, fullSilent: boolean=false) => {
    if(!fullSilent) {
      setActionLoading(true);
    }
    await setDoc(doc(db, "prices", "current"), {prices: newPrices});
    if(!silent) {
      setStatus({message: "Preis-Änderungen-Tabelle wurde erfolgreich gespeichert.", type: "success"})
    }
    if(!fullSilent) {
      setActionLoading(false);
    }
  };

  const updatePrices = async (prices: Prices, deletion?: boolean) => {
    if(deletion) {
      await onConfirmation(prices.length > 0 ? "Sind Sie sich sicher, dass Sie dieses Preis-Element löschen wollen?" : "Sind Sie sich sicher, dass Sie alle Preis-Elemente löschen wollen?", async () => {
        await savePriceList(prices, true);
        setPrices(prices);
      });
    }
    else {
      setPrices(prices);
    }
  };

  const test = async () => {
    await testing(
      {
        prices,
        apiUsers: settings.apiUsers.users.filter((user) => user.active),
        triggerTag: settings.triggerTag.value,
      }
    );
  };

  return (
    <MainContainer loading={loading}>
      <ConfirmationModal
        modalInfo={modalInfo}
        onClose={() => setModalInfo({show: false})}
        onConfirm={async () => {
          if(modalInfo.callback) {
            await modalInfo.callback();
          }
          setModalInfo({show: false});
        }
      }
      />
      <Status loading={actionLoading} status={status} />
      <div className="container--split">
        <PriceRows
          products={availableProducts}
          prices={prices}
          onChange={updatePrices}
          onSaveClick={() => savePriceList()}
        />
        <div className="container--vertical">
          <UpcomingTasks tasks={upcomingTasks} onRefresh={refreshUpcoming} onDelete={deleteTaskHandling} />
          <ExecutionOptions onClick={push} />
          <Logs processLogs={processLogs} />
        </div>
      </div>

    </MainContainer>
  );
};

export default Home;
