import {DocumentData} from "firebase/firestore";

export function docsToObject(docs: any) {
  let object: any = {};
  docs.forEach((doc: DocumentData) => {
    object[doc.id] = doc.data();
  });
  return object;
}

export function setDeepValue(data: object, key: string, value: any, deepKey?: string) {
  const copy = JSON.parse(JSON.stringify(data));
  if(deepKey) {
    if(copy[key] === undefined) {
      copy[key] = {};
    }
    copy[key][deepKey] = value;
  }
  else {
    copy[key] = value;
  }
  return copy;
}

export function updateObjectArray(arr: any, index: number, overwrite: object) {
  const updatedList = arr.map((element: object, i: number) => {
    if(index === i) {
      return {...element, ...overwrite}
    }
    else {
      return element;
    }
  });
  return updatedList;
};